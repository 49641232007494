import { FunctionalComponent, h } from "preact";
import { Route, Router } from "preact-router";
import { useState } from 'preact/hooks';
import Home from "../routes/home";
import Redirect from "../routes/redirect";
import Submit from "../routes/submit";
import Share from "../routes/share";
import Top from "../routes/top";
import Terms from "../routes/terms";
import { GlobalContext } from "../journey/GlobalContext";
import { GlobalContextType } from "../journey/types";
import Create from "../routes/create";
import View from "../routes/view";

const App: FunctionalComponent = () => {

    const [global, setGlobal] = useState<GlobalContextType>({
        journeys: undefined,
        product: undefined,
        colour: undefined,
        pattern: undefined,
        twoDimensional: undefined,
        threeDimensional: undefined
    });

    const value = [global, setGlobal];

    return (
        <div id="app">
            <GlobalContext.Provider value={value}>
                <Router>
                    <Route path="/" component={Home} />
                    <Route path="/create" component={Create} />
                    <Route path="/submit" component={Submit} />
                    <Route path="/share/:submissionId/:f?/:c?/:p?/:twd?/:thd?" component={Share} />
                    <Route path="/top" component={Top} />
                    <Route path="/terms" component={Terms} />
                    <Route path="/view/:submissionId/:f?/:c?/:p?/:twd?/:thd?" component={View} />
                    <Redirect default to="/" />
                </Router>
            </GlobalContext.Provider>
        </div>
    );
};

export default App;
